import React, { useEffect, useState } from "react";

import { Box, Button, Header, Table } from "@cloudscape-design/components";
import { useCallback } from "react";
import ApiService from "../../services/ApiService";

const apiService = new ApiService();

function Rekognizer() {
  const [pending, setPending] = useState([]);
  const [loading, setLoading] = useState({});

  const fetchCompanies = useCallback(async () => {
    setPending(await apiService.getCompanies());
  }, [setPending]);

  useEffect(() => {
    fetchCompanies();
    const interval = setInterval(async () => {
      await fetchCompanies();
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchCompanies]);

  return (
    <Table
      columnDefinitions={[
        {
          id: "company",
          header: "Company",
          cell: (item) => item.company || "-",
        },
        {
          id: "status",
          header: "Status",
          cell: (item) => item.moderationStatus || "-",
        },
        {
          id: "approve",
          header: "Approve",
          cell: (item) => (
            <Button
              disabled={loading[item.companyId]}
              onClick={async () => {
                setLoading((loading) => ({
                  ...loading,
                  [item.companyId]: true,
                }));
                await apiService.moderate({
                  companyId: item.companyId,
                  action: "approved",
                });
                await fetchCompanies();
              }}
              formAction="none"
              iconName="check"
              variant="primary"
            >
              Approve
            </Button>
          ),
        },
        {
          id: "deny",
          header: "Deny",
          cell: (item) => (
            <Button
              disabled={loading[item.companyId]}
              formAction="none"
              iconName="close"
              variant="primary"
              onClick={async () => {
                setLoading((loading) => ({
                  ...loading,
                  [item.companyId]: true,
                }));
                await apiService.moderate({
                  companyId: item.companyId,
                  action: "denied",
                });
                await fetchCompanies();
              }}
            >
              Deny
            </Button>
          ),
        },
      ]}
      items={pending}
      loadingText="Loading resources"
      sortingDisabled
      empty={
        <Box textAlign="center" color="inherit">
          <b>No companies</b>
        </Box>
      }
      header={<Header> Moderation queue </Header>}
    />
  );
}

export default Rekognizer;
