import { Auth } from "aws-amplify";
import superagent from "superagent";

const api_url =
  "https://yc4oxhkfjd.execute-api.eu-central-1.amazonaws.com/Prod";

class ApiService {
  async sleep(duration = 1000) {
    return await new Promise((resolve) =>
      setTimeout(() => resolve(), duration)
    );
  }

  async getToken() {
    const data = await Auth.currentSession();

    return data.getIdToken().jwtToken;
  }

  async getCompanies() {
    const result = await superagent
      .get(`${api_url}/companies`)
      .query({ status: "pending" })
      .set("accept", "application/json")
      .set("authorization", `Bearer ${await this.getToken()}`)
      .send();

    return result.body;
  }

  async moderate({ companyId, action }) {
    const result = await superagent
      .put(`${api_url}/companies`)
      .set("accept", "application/json")
      .set("authorization", `Bearer ${await this.getToken()}`)
      .send({ companyId, action });
  }
}

export default ApiService;
