import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  AppLayout,
  ColumnLayout,
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import { Amplify, Auth } from "aws-amplify";
import Moderation from "./components/moderation/Moderation";

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_lGbru3zX8",
    userPoolWebClientId: "3aujc750ora77fjuuh8g4ghpae",
  },
});
Auth.configure();

function App({ signOut, user }) {
  return (
    <AppLayout
      headerSelector="#header"
      toolsHide
      navigationHide
      content={
        <ContentLayout
          header={<Header variant="h1">Moderation Dashboard</Header>}
        >
          <ColumnLayout columns={1}>
            <Moderation />
          </ColumnLayout>
        </ContentLayout>
      }
      // breadcrumbs={<Breadcrumbs />}
      // navigation={<Navigation />}
      // tools={toolsContent}
      // toolsOpen={toolsOpen}
      // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      // ariaLabels={appLayoutLabels}
      // notifications={<Notifications />}
    ></AppLayout>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
